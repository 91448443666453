import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderSec = _resolveComponent("HeaderSec")!
  const _component_TitleSection = _resolveComponent("TitleSection")!
  const _component_AboutSec = _resolveComponent("AboutSec")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_CurrentHappenings = _resolveComponent("CurrentHappenings")!
  const _component_ExperienceSec = _resolveComponent("ExperienceSec")!
  const _component_ProjectSection = _resolveComponent("ProjectSection")!
  const _component_FooterBottom = _resolveComponent("FooterBottom")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_app, null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_HeaderSec)
        ]),
        _createVNode(_component_v_main, { class: "no-padding" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_TitleSection, { id: "home" })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_v_container, null, {
                default: _withCtx(() => [
                  _createVNode(_component_AboutSec, { id: "about" })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_CurrentHappenings, { id: "quasp" })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_ExperienceSec, { id: "experience" })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_ProjectSection, { id: "projects" })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_FooterBottom, { id: "contact" })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}